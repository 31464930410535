'use client'

import {
    type InputHTMLAttributes,
    forwardRef,
    type ForwardedRef,
    useState,
    type ReactNode,
    useMemo,
    type Dispatch,
    type SetStateAction,
} from 'react'
import { cn } from '@repo/ui/lib/utils'
import { Button } from '@repo/ui/button'
import { RichEditor, type RichEditorProps } from '@repo/ui/rich-editor'
import type { Editor as TinyMCEEditor } from 'tinymce'
import { EyeIcon, EyeOffIcon } from 'lucide-react'
import { useController, useFormContext } from 'react-hook-form'
import { useFormField } from '@repo/ui/form'

type Literal<S> = S extends string ? (string extends S ? never : S) : never

export type Type = Literal<InputHTMLAttributes<HTMLInputElement>['type']>

export type InputProps =
    | (InputHTMLAttributes<HTMLInputElement> & { type: Type })
    | ({ type: 'rich'; name?: string } & Omit<RichEditorProps, 'initialValue'> & {
        defaultValue: RichEditorProps['initialValue']
    })

function Input({ className, type, ref, ...props }: InputProps & { ref?: ForwardedRef<HTMLInputElement | TinyMCEEditor> }) {
    if (type === 'rich') {
        let { name } = props
        if (name === undefined) ({ name } = useFormField())

        const { control } = useFormContext()
        let {
            field: { onChange, ...field },
        } = useController({ control, name })

        if (props.onChange && onChange) {
            const oc1 = props.onChange
            const oc2 = onChange
            onChange = arg => {
                oc1(arg)
                oc2(arg)
            }
        }

        let initialValue: string | undefined
        if (typeof props.defaultValue === 'string') initialValue = props.defaultValue
        else if (Array.isArray(props.defaultValue)) initialValue = props.defaultValue.join()
        else initialValue = props.defaultValue?.toString()

        return (
            <RichEditor
                {...{
                    ...field,
                    ...(props as RichEditorProps),
                    onChange,
                    initialValue,
                    className,
                    ref: ref as ForwardedRef<TinyMCEEditor>,
                    suppressHydrationWarning: true,
                }}
            />
        )
    }

    let eye: ReactNode = <></>
    if (type === 'password') {
        let setType: Dispatch<SetStateAction<'text' | 'password'>> | undefined
            ;[type, setType] = useState<'text' | 'password'>('password')
        eye = useMemo(
            () => (
                <div
                    tabIndex={-1}
                    className='absolute right-1 top-1/2 -translate-y-1/2 w-8 h-8 flex items-center justify-center cursor-pointer text-muted-foreground hover:text-foreground transition-colors'
                    onKeyUp={({ key, currentTarget }) => {
                        if (key === 'Enter' || key === ' ') currentTarget?.dispatchEvent(new Event('click'))
                    }}
                    onClick={() => {
                        if (type === 'password') setType('text')
                        else setType('password')
                    }}
                >
                    {type === 'text' && <EyeOffIcon size={16} />}
                    {type === 'password' && <EyeIcon size={16} />}
                </div>
            ),
            [type, setType]
        )
    }

    return (
        <div className='relative'>
            <input
                {...(props as InputHTMLAttributes<HTMLInputElement>)}
                ref={ref as ForwardedRef<HTMLInputElement>}
                type={type}
                className={cn(
                    'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
                    className
                )}
            />

            {eye}
        </div>
    )
}

export { Input }
