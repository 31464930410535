'use client'

import dynamic from 'next/dynamic'
import Script from 'next/script'
import { forwardRef, type Ref, type RefAttributes, useEffect, useRef, useState } from 'react'
import { Editor as ReactEditor, type IAllProps } from '@tinymce/tinymce-react'
import { EditorManager, Editor as TinyMCEEditor, type EditorEvent } from 'tinymce'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> { }
interface EditorProps extends IAllProps { }

export interface RichEditorProps {
    className?: InputProps['className']
    dark?: boolean
    inline?: boolean

    placeholder?: string
    initialValue?: EditorProps['initialValue']
    value?: EditorProps['value']

    onInit?: EditorProps['onInit']
    onChange?: (
        text: Parameters<NonNullable<EditorProps['onEditorChange']>>[0]
    ) => ReturnType<NonNullable<EditorProps['onEditorChange']>>
    onDirty?: (dirty?: boolean) => ReturnType<NonNullable<EditorProps['onDirty']>>
}

export const PLUGINS = [
    'advlist',
    'autolink',
    'autoresize',
    'lists',
    'link',
    'image',
    'charmap',
    'anchor',
    'searchreplace',
    'visualblocks',
    'code',
    'fullscreen',
    'insertdatetime',
    'media',
    'table',
    'preview',
    'help',
    'wordcount',
    'quickbars',
] as const

const plugins = PLUGINS.join(' ')

export const TOOLBAR = [
    ['undo', 'redo'],
    ['blocks'],
    ['bold', 'italic', 'forecolor'],
    ['alignleft', 'aligncenter', 'alignright', 'alignjustify'],
    ['bullist', 'numlist', 'outdent', 'indent'],
    ['quicktable'],
    ['code', 'removeformat', 'help'],
] as const

const toolbar = TOOLBAR.flatMap(x => [...x, '|'])
    .join(' ')
    .slice(0, -' |'.length)

export const DIRTY_TIMEOUT = 200 as const

const RichEditor_IMPL = (
    {
        inline,

        placeholder,
        initialValue,
        value,

        onInit,
        onChange,
        onDirty,

        ref,
        ...props
    }: RichEditorProps & { ref?: Ref<TinyMCEEditor> }
) => {
    "use no memo"

    if (typeof ref === 'function' || ref === undefined || ref === null)
        ref = useRef<TinyMCEEditor>(null)

    inline ??= false
    placeholder ??= 'Type here...'

    const [dirty, setDirty] = useState(false)
    let dirtyTimeout: ReturnType<typeof setTimeout> | undefined

    let setValue: ReturnType<typeof useState<EditorProps['value']>>[1] | undefined

    // if (value !== undefined) {
    //     ;[value, setValue] = useState<EditorProps['value']>(value ?? initialValue ?? '')
    //     useEffect(() => setValue?.(initialValue ?? ''), [initialValue, setValue])
    // }

    const prefersDark = (options: { themePref?: MediaQueryList } = {}) => {
        const theme = globalThis.localStorage?.getItem('theme') ?? null
        options.themePref ??= globalThis.matchMedia?.('(prefers-color-scheme: dark)')
        if (!options.themePref && globalThis.MediaQueryList) options.themePref = new globalThis.MediaQueryList()

        let dark = false
        if (theme === 'dark') dark = true
        else if (theme === 'light') dark = false
        else if (theme === 'system') {
            if (options.themePref) dark = options.themePref.matches
            else if (globalThis.document?.documentElement)
                dark = globalThis.document.documentElement.style.getPropertyValue('color-schema') === 'dark'
        }

        return dark
    }

    const [skin, setSkin] = useState<'oxide-dark' | undefined>(prefersDark() ? 'oxide-dark' : undefined)
    const [content_css, setContentCss] = useState<'dark' | undefined>(skin === 'oxide-dark' ? 'dark' : undefined)

    const themeListener = ({ matches }: MediaQueryListEvent) => {
        let skin: 'oxide-dark' | undefined
        let content_css: 'dark' | undefined

        if (matches) {
            skin = 'oxide-dark'
            content_css = 'dark'
        }

        setSkin(skin)
        setContentCss(content_css)

        let editor = typeof ref === 'function' ? null : ref?.current
        if (!editor) return

        const content = editor.getContent?.() ?? ''
        const { id, editorManager } = editor ?? {}

        editor?.remove?.()

        const SETTINGS = {
            id,

            skin,
            content_css,

            element_format: 'xhtml',
            forced_root_block: 'div',
            remove_linebreaks: false,
            remove_redundant_brs: false,
            cleanup_on_startup: false,
            cleanup: false,
            verify_html: false,
            apply_source_formatting: false,
            fix_nesting: false,
            fix_table_elements: false,
            fix_list_elements: false,
            fix_content_duplication: false,
            preformatted: false,

            resize: inline,
            placeholder: placeholder,
            branding: false,
            statusbar: false,
            promotion: false,
            menubar: false,
            paste_data_images: true,
            default_link_target: '_blank',

            quickbars_insert_toolbar: false,
            quickbars_selection_toolbar: 'quicklink',
            quickbars_image_toolbar: false,

            inline,
            plugins,
            toolbar,

            license_key: 'gpl',
        } as const

        if (editorManager) editor = editorManager.createEditor(id, SETTINGS)
        // else editor = new TinyMCEEditor(id, SETTINGS)

        if (typeof ref === 'function') ref(editor)
        else ref.current = editor

        editor?.setContent?.(content)
        editor?.load?.()
        editor?.render?.()
    }

    globalThis.addEventListener?.('storage', ({ key, newValue, oldValue }) => {
        if (key !== 'theme') return
        if (oldValue === newValue) return

        let matches: boolean | undefined
        if (newValue === 'dark') matches = true
        else if (newValue === 'light') matches = false
        else if (newValue === 'system') {
            const themePref: MediaQueryList | undefined = globalThis.matchMedia?.('(prefers-color-scheme: dark)')

            if (themePref) ({ matches } = themePref)
            else if (globalThis.document?.documentElement)
                matches = globalThis.document.documentElement.style.getPropertyValue('color-schema') === 'dark'
        }

        themeListener(
            new MediaQueryListEvent('change', {
                media: '(prefers-color-scheme: dark)',
                matches,
            })
        )
    })

    const onInitChangeTheme = () => {
        const options: Parameters<typeof prefersDark>[0] = {}
        const dark = prefersDark(options)

        const { themePref } = options
        themePref?.addEventListener('change', themeListener)

        themeListener(
            new MediaQueryListEvent('change', {
                media: '(prefers-color-scheme: dark)',
                matches: dark,
            })
        )

        return () => {
            themePref?.removeEventListener('change', themeListener)
        }
    }

    useEffect(onInitChangeTheme, [])

    return (
        <ReactEditor
            ref={ref as unknown as RefAttributes<InstanceType<typeof ReactEditor>>['ref']}
            tinymceScriptSrc={[{ src: '/tinymce/tinymce.min.js', async: true, defer: true }]}
            licenseKey='gpl'
            inline={inline}
            initialValue={initialValue}
            value={value}
            init={{
                skin,
                content_css,

                element_format: 'xhtml',
                forced_root_block: 'div',
                remove_linebreaks: false,
                remove_redundant_brs: false,
                cleanup_on_startup: false,
                cleanup: false,
                verify_html: false,
                apply_source_formatting: false,
                fix_nesting: false,
                fix_table_elements: false,
                fix_list_elements: false,
                fix_content_duplication: false,
                preformatted: false,

                resize: inline,
                placeholder: placeholder,
                branding: false,
                statusbar: false,
                promotion: false,
                menubar: false,
                paste_data_images: true,
                default_link_target: '_blank',

                quickbars_insert_toolbar: false,
                quickbars_selection_toolbar: 'quicklink',
                quickbars_image_toolbar: false,
            }}
            onInit={(
                event: Parameters<NonNullable<EditorProps['onInit']>>[0],
                editor: Parameters<NonNullable<EditorProps['onInit']>>[1]
            ): ReturnType<NonNullable<EditorProps['onInit']>> => {
                if (typeof ref === 'function') ref(editor)
                else ref.current = editor

                if (inline) {
                    const c = editor.getContentAreaContainer()

                    for (const x of [
                        'absolute',
                        'borderblock',
                        'bg-transparent',
                        'text-gray-900',
                        'focus:ring-0',
                        'sm:text-sm',
                        'sm:leading-6',
                        'rounded-md',
                        'shadow-xs',
                        'ring-1',
                        'ring-inset',
                        'ring-gray-300',
                        'focus-within:ring-2',
                        'focus-within:ring-inset',
                        'focus-within:ring-primary',
                        'sm:max-w-md',
                    ])
                        c.classList.add(`!${x}`)
                }

                if (onChange) {
                    editor.on('ExecCommand', ({ command }) => {
                        if (
                            [
                                'Bold',
                                'Italic',
                                'Underline',
                                'Strikethrough',
                                'Superscript',
                                'Subscript',
                                'Cut',
                                'Paste',
                                'ForeColor',
                                'BackColor',
                                'JustifyLeft',
                                'JustifyCenter',
                                'JustifyRight',
                                'JustifyFull',
                                'FontName',
                                'FontSize',
                                'FormatBlock',
                                'RemoveFormat',
                                'Indent',
                                'Outdent',
                                'CreateLink',
                                'Unlink',
                                'InsertHorizontalRule',
                                'InsertParagraph',
                                'InsertText',
                                'InsertHTML',
                                'InsertImage',
                                'Delete',
                                'ForwardDelete',
                                'Redo',
                                'Undo',
                                'HiliteColor',
                                'InsertLineBreak',
                                'InsertNewBlockAfter',
                                'InsertNewBlockBefore',
                                'JustifyNone',
                                'Lang',
                                'LineHeight',
                                'mceApplyTextcolor',
                                'mceBlockQuote',
                                'mceCleanup',
                                'mceInsertClipboardContent',
                                'mceInsertContent',
                                'mceInsertLink',
                                'mceInsertNewLine',
                                'mceNewDocument',
                                'mceRemoveNode',
                                'mceRemoveTextcolor',
                                'mceReplaceContent',
                                'mceSetContent',
                                'mceToggleFormat',
                                'mceTableSizingMode',
                                'mceTableApplyCellStyle',
                                'mceTableSplitCells',
                                'mceTableMergeCells',
                                'mceTableInsertRowBefore',
                                'mceTableInsertRowAfter',
                                'mceTableInsertColBefore',
                                'mceTableInsertColAfter',
                                'mceTableDeleteCol',
                                'mceTableDeleteRow',
                                'mceTableCutRow',
                                'mceTableCutCol',
                                'mceTablePasteRowBefore',
                                'mceTablePasteRowAfter',
                                'mceTablePasteColBefore',
                                'mceTablePasteColAfter',
                                'mceTableDelete',
                                'mceTableCellToggleClass',
                                'mceTableTogleCaption',
                                'mceInserTable',
                                'mceTableRowType',
                                'mceTableColType',
                                'mceTableCellType',
                                'ApplyOrderedListStyle',
                                'ApplyUnorderedListStyle',
                                'mceAdvancedTableSort',
                                'mceSortTableAdvanced',
                                'mceSortTableByColumnAsc',
                                'mceSortTableByColumnDesc',
                                'mceSortTableByRowAsc',
                                'mceSortTableByRowDesc',
                                'mceTableToggleSeries',
                                'mceLowerCase',
                                'mceTitleCase',
                                'mceUpperCase',
                                'tc-delete-conversation-at-cursor',
                                'tc-try-delete-all-conversations',
                                'mceDirectionLTR',
                                'mceDirectionRTL',
                                'mceImageRotateRight',
                                'mceImageRotateLeft',
                                'mceImageFlipVertical',
                                'mceImageFlipHorizontal',
                                'mceInsertFootnote',
                                'mceUpdateFootnotes',
                                'mcePaintFormats',
                                'mceInsertDate',
                                'mceInsertTime',
                                'mceLink',
                                'InsertDefinitionList',
                                'InsertOrderedList',
                                'InsertUnorderedList',
                                'mceListUpdate',
                                'mceListProps',
                                'RemoveList',
                                'mceNonBreaking',
                                'mcePageBreak',
                                'mceInsertClipboardContent',
                                'mceCancel',
                                'mceSave',
                                'mceInsertToc',
                                'mceUpdateToc',
                                'mceInsertTemplate',
                            ].includes(command)
                        )
                            onChange(editor.getContent())
                    })

                    for (const event of [
                        'FormatApply',
                        'FormatRemove',
                        'NodeChange',
                        'input',
                        'Change',
                        'Redo',
                        'Undo',
                    ] as const) {
                        editor.on(event, (e: EditorEvent<typeof event>) => {
                            onChange(editor.getContent())
                        })
                    }
                }

                return onInit?.(event, editor)
            }}
            onDirty={() => {
                setDirty(true)
                onDirty?.(true)
            }}
            onEditorChange={(text, editor) => {
                if (onDirty) {
                    if (dirtyTimeout) {
                        clearTimeout(dirtyTimeout)
                        dirtyTimeout = undefined
                    }

                    dirtyTimeout = setTimeout(() => {
                        setDirty(initialValue === editor.getContent())
                        onDirty(dirty)
                    }, DIRTY_TIMEOUT)
                }

                onChange?.(text)
                setValue?.(text)
            }}
            plugins={plugins}
            toolbar={toolbar}
            {...props}
        />
    )
}

const RichEditor = dynamic(
    async () => {
        try {
            return RichEditor_IMPL

            await import('tinymce/tinymce')
            // await import('tinymce/tinymce.js')
            // @ts-ignore
            await import('tinymce/tinymce.min.js')
            // @ts-ignore
            await import('tinymce/models/dom')
            // @ts-ignore
            await import('tinymce/themes/silver')
            // @ts-ignore
            await import('tinymce/icons/default')

            await Promise.all([
                // @ts-ignore
                import('tinymce/skins/content/dark/content.css'),
                // @ts-ignore
                import('tinymce/skins/content/dark/content.min.css'),
                // @ts-ignore
                import('tinymce/skins/content/dark/content.js'),

                // @ts-ignore
                import('tinymce/skins/content/default/content.css'),
                // @ts-ignore
                import('tinymce/skins/content/default/content.min.css'),
                // @ts-ignore
                import('tinymce/skins/content/default/content.js'),

                // @ts-ignore
                import('tinymce/skins/ui/oxide-dark/content.css'),
                // @ts-ignore
                import('tinymce/skins/ui/oxide-dark/content.min.css'),
                // @ts-ignore
                import('tinymce/skins/ui/oxide-dark/content.js'),
                // @ts-ignore
                import('tinymce/skins/ui/oxide-dark/skin.css'),
                // @ts-ignore
                import('tinymce/skins/ui/oxide-dark/skin.min.css'),
                // @ts-ignore
                import('tinymce/skins/ui/oxide-dark/skin.js'),

                // @ts-ignore
                import('tinymce/skins/ui/oxide/content.css'),
                // @ts-ignore
                import('tinymce/skins/ui/oxide/content.min.css'),
                // @ts-ignore
                import('tinymce/skins/ui/oxide/content.js'),
                // @ts-ignore
                import('tinymce/skins/ui/oxide/skin.css'),
                // @ts-ignore
                import('tinymce/skins/ui/oxide/skin.min.css'),
                // @ts-ignore
                import('tinymce/skins/ui/oxide/skin.js'),
            ])

            await Promise.all(PLUGINS.map(x => import(`tinymce/plugins/${x}` as const)))
        } catch (e) {
            console.error(e)
        }

        return RichEditor_IMPL
    },
    { ssr: true }
)

RichEditor.displayName = 'RichEditor'

export { RichEditor }
